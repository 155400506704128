<template>
  <v-row class="error-row">
    <v-col xl="5" lg="5" md="5" sm="5" cols="12">
      <!-- Eyes Emoji 👀 -->
      <div class="emoji">&#x1F440;</div>

     <v-list-item three-line>
        <v-list-item-content>
          <div style="border-bottom: 1px solid #80808026;padding: 10px;padding-right: 8%;"
            class="headline mb-4 text-left">Error 404</div>
          <v-list-item-title class="mb-1 text-left pl-3 mb-5">
            <span class="font-weight-bold">Está página no existe</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item> 
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {

    };
  },
  created() {
    this.$router.push({ name: "home" });

  },
  mounted() {

  },
  methods: {

  },
  computed: {

  }
}; 
</script>

<style lang="scss">
.error-row {
  height: calc(100vh - 80px);
  align-items: center;
  text-align: center;
}

.emoji {
  font-size: 5em;
  /* Make the emoji big */
  margin-bottom: 20px;
  /* Spacing from the emoji to the error message */
}
</style>
